export const Shiyu6_1 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 27.91,
    rank: 1,
    avg_round: 3.0,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 19.88,
    rank: 2,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 6.24,
    rank: 3,
    avg_round: 2.99,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 5.67,
    rank: 4,
    avg_round: 2.99,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 3.49,
    rank: 5,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 2.28,
    rank: 6,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Soukaku',
    app_rate: 2.28,
    rank: 6,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lighter',
    app_rate: 1.57,
    rank: 8,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 1.57,
    rank: 8,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 1.44,
    rank: 10,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 1.15,
    rank: 11,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.81,
    rank: 12,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.79,
    rank: 13,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.71,
    rank: 14,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.71,
    rank: 14,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.68,
    rank: 16,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.68,
    rank: 16,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.66,
    rank: 18,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.63,
    rank: 19,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.6,
    rank: 20,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.55,
    rank: 21,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.5,
    rank: 22,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.5,
    rank: 22,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.47,
    rank: 24,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.47,
    rank: 24,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.45,
    rank: 26,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.37,
    rank: 27,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.37,
    rank: 27,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.31,
    rank: 29,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.31,
    rank: 29,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.31,
    rank: 29,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.31,
    rank: 29,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.29,
    rank: 33,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.26,
    rank: 34,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.26,
    rank: 34,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.26,
    rank: 34,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.26,
    rank: 34,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.24,
    rank: 38,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Rina',
    app_rate: 0.24,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.24,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.24,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.21,
    rank: 42,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.21,
    rank: 42,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 42,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.21,
    rank: 42,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 46,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.18,
    rank: 46,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.18,
    rank: 46,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 46,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.18,
    rank: 46,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.18,
    rank: 46,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.18,
    rank: 46,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.18,
    rank: 46,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.18,
    rank: 46,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.18,
    rank: 46,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.16,
    rank: 56,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.16,
    rank: 56,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.16,
    rank: 56,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.16,
    rank: 56,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.16,
    rank: 56,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.13,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lighter',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.13,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.13,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.13,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 69,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.1,
    rank: 69,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 83,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 83,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 83,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 83,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 83,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 83,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 83,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 83,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 83,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Lycaon',
    app_rate: 0.08,
    rank: 83,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 83,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 94,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 94,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 94,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Lycaon',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 94,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Lighter',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Lighter',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Nekomata',
    char_three: 'billy-kid',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Lighter',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Yanagi',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Anton',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'billy-kid',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 139,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 139,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Burnice',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 139,
    avg_round: 3,
    star_num: '4'
  }
];
