export const Shiyu7_1 = [
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 10.08,
    rank: 1,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 9.87,
    rank: 2,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 7.63,
    rank: 3,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 5.11,
    rank: 4,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 5.0,
    rank: 5,
    avg_round: 2.99,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 3.91,
    rank: 6,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 3.61,
    rank: 7,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 3.5,
    rank: 8,
    avg_round: 2.99,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 2.19,
    rank: 9,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 1.67,
    rank: 10,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 1.56,
    rank: 11,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 1.42,
    rank: 12,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 1.42,
    rank: 12,
    avg_round: 2.95,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 1.39,
    rank: 14,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 1.18,
    rank: 15,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 1.18,
    rank: 15,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 1.09,
    rank: 17,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 1.01,
    rank: 18,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.85,
    rank: 19,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.79,
    rank: 20,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.77,
    rank: 21,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.77,
    rank: 21,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.74,
    rank: 23,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.71,
    rank: 24,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.71,
    rank: 24,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.68,
    rank: 26,
    avg_round: 2.76,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.68,
    rank: 26,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.66,
    rank: 28,
    avg_round: 2.58,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.66,
    rank: 28,
    avg_round: 2.47,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.66,
    rank: 28,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Soukaku',
    app_rate: 0.63,
    rank: 31,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.63,
    rank: 31,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.55,
    rank: 33,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.52,
    rank: 34,
    avg_round: 2.76,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.49,
    rank: 35,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.49,
    rank: 35,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.46,
    rank: 37,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.46,
    rank: 37,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.46,
    rank: 37,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.46,
    rank: 37,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.44,
    rank: 41,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.44,
    rank: 41,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.41,
    rank: 43,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.36,
    rank: 44,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 44,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.36,
    rank: 44,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 44,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.36,
    rank: 44,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.36,
    rank: 44,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lighter',
    app_rate: 0.33,
    rank: 50,
    avg_round: 2.78,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.33,
    rank: 50,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.33,
    rank: 50,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.33,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.33,
    rank: 50,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.3,
    rank: 55,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.3,
    rank: 55,
    avg_round: 2.7,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.3,
    rank: 55,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.3,
    rank: 55,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.3,
    rank: 55,
    avg_round: 2.64,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.27,
    rank: 60,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.27,
    rank: 60,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.25,
    rank: 62,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.25,
    rank: 62,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.25,
    rank: 62,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.25,
    rank: 62,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.25,
    rank: 62,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.25,
    rank: 62,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.22,
    rank: 68,
    avg_round: 2.38,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.22,
    rank: 68,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.22,
    rank: 68,
    avg_round: 2.43,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.19,
    rank: 71,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.19,
    rank: 71,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.19,
    rank: 71,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.19,
    rank: 71,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Seth',
    app_rate: 0.19,
    rank: 71,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.19,
    rank: 71,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.16,
    rank: 77,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 77,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 79,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 79,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'anby-demara',
    app_rate: 0.14,
    rank: 79,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.14,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.14,
    rank: 79,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 79,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 79,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.14,
    rank: 79,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 79,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 90,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.11,
    rank: 90,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.25,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 90,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 90,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 90,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.11,
    rank: 90,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'anby-demara',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Lighter',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lighter',
    char_three: 'Ben',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 108,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 108,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Lighter',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lighter',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Lycaon',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.05,
    rank: 134,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.05,
    rank: 134,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 134,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Ben',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Yanagi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lighter',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Lycaon',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lighter',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Burnice',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Yanagi',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Nekomata',
    char_three: 'billy-kid',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Seth',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Lighter',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lighter',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Yanagi',
    char_three: 'Burnice',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Lighter',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Anton',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lighter',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Yanagi',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lighter',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lighter',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lighter',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lighter',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Burnice',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Lighter',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.03,
    rank: 175,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.03,
    rank: 175,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.03,
    rank: 175,
    avg_round: 3,
    star_num: '4'
  }
];
